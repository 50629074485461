<template>
  <div>
    <v-row align="center" justify="end">
      <v-chip small label v-if="selectedParent" @click="addChildProduct()">
        <v-icon left>add</v-icon>
        Add Child Product to {{ selectedParent.name }}
      </v-chip>

      <v-col cols="12" sm="6" md="4">
        <el-input
          ref="searchBar"
          suffix-icon="el-icon-search"
          v-model="params.search"
          clearable
          placeholder="Search"
        >
          <!-- <template slot="prepend">
            <el-button @click="addProduct"
              ><i class="el-icon-plus"></i
            ></el-button>
          </template> -->
          <template slot="append">
            <v-badge
              :key="updateBadge"
              :content="activeFilters()"
              :value="activeFilters() > 0"
              color="primary"
              offset-y="10"
            >
              <el-button
                @click="filterDialog = true"
                el-tooltip="Advanced Filter"
              >
                <v-icon small>filter_alt</v-icon>
              </el-button>
            </v-badge>
          </template>
        </el-input>
      </v-col>
    </v-row>
    <v-data-table
      class="mt-3"
      dense
      fixed-header
      hide-default-footer
      :loading="loading"
      :items="products.data"
      :headers="headers"
      disable-pagination
      height="65vh"
      :calculate-widths="true"
      style="cursor: pointer"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-row justify="center">
          <v-btn icon small
            ><v-icon small @click="editItem(item)">edit</v-icon></v-btn
          >
          <!-- <v-btn icon small color="danger" @click="deleteItem(item)"
            ><v-icon small>delete</v-icon></v-btn
          > -->
        </v-row>
      </template>

      <template v-slot:[`item.trademark`]="{ item }">
        <v-icon v-if="item.trademark" color="success">check</v-icon>
        <v-icon v-else color="danger">close</v-icon>
      </template>
      <template v-slot:[`item.codes`]="{ item }">
        <v-chip
          small
          style="font-size: 12px"
          v-for="product in item.productCountries"
          :key="product.id"
        >
          {{ product.name }} ({{ product.code }})
        </v-chip>
      </template>

      <template v-slot:[`item.patent`]="{ item }">
        <v-icon v-if="item.patent" color="success">check</v-icon>
        <v-icon v-else color="danger">close</v-icon>
      </template>
    </v-data-table>
    <v-col cols="12" class="text-center">
      <el-pagination
        style="color: var(--v-primaryText-base)"
        :current-page.sync="page"
        :pager-count="5"
        :page-size.sync="params.limit"
        :page-sizes="[18, 25, 50, 100]"
        :layout="
          !$vuetify.breakpoint.mobile
            ? 'sizes, prev, pager, next, jumper, total'
            : 'prev, pager, next'
        "
        :total="products.total"
      >
      </el-pagination>
    </v-col>

    <!-- Product Dialog -->
    <v-dialog
      v-model="dialog"
      persistent
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Product </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="primary"
            text
            @click="saveProduct"
            :loading="savingProduct"
            >Save</v-btn
          > -->
          <v-btn
            text
            @click="
              (dialog = false),
                (subItems = {
                  total: 0,
                  data: [],
                }),
                (productMetadata = [])
            "
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                label="Parent Item"
                outlined
                disabled
                hide-no-data
                dense
                v-model="item.parentProduct"
                :loading="searchingProducts"
                :search-input.sync="searchParentProducts"
                :menu-props="{ closeOnContentClick: true }"
                :items="searchProducts.data"
                item-text="name"
                return-object
                item-value="id"
                clearable
              >
                <template v-slot:selection="{ attr, on, item }">
                  <span v-on="on" v-bind="attr">{{ item.name }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-text-field
                v-model="item.name"
                label="Name*"
                outlined
                disabled
                dense
              ></v-text-field>
              <v-text-field
                v-model="item.botanicalName"
                label="Botanical Name"
                outlined
                disabled
                dense
              ></v-text-field>
              <v-text-field v-model="item.code" label="Code" outlined dense></v-text-field>

              <v-select
                v-model="item.type"
                outlined
                dense
                disabled
                label="Type*"
                :items="types"
              ></v-select>
              <v-textarea
                rows="3"
                v-model="item.description"
                label="Description"
                outlined
                disabled
                dense
              ></v-textarea>
              <v-list-item @click="hsCodeSearchModal = true" disabled>
                <v-list-item-action>
                  <v-icon :color="!item.hsCode ? 'orange' : null"
                    >qr_code_2</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="item.hsCode">
                    {{ item.hsCode.alternateCode }}
                  </v-list-item-title>
                  <v-list-item-title v-else> No Code listed </v-list-item-title>
                  <v-list-item-subtitle> HS Code </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-checkbox label="Patent" disabled v-model="item.patent"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    label="Trademark"
                    disabled
                    v-model="item.trademark"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="8">
              <v-row>
                <v-col cols="12" class="my-0 py-0">
                  <v-breadcrumbs
                    :items="
                      productTree.map((x) => ({
                        text: x.hsCode
                          ? x.name + ` (${x.hsCode.alternateCode})`
                          : x.name,
                        disabled: true,
                      }))
                    "
                  >
                    <template v-slot:divider>
                      <v-icon>chevron_right</v-icon>
                    </template>
                  </v-breadcrumbs>
                  <!-- <v-row align="center" class="my-0 py-0">
                    <template v-for="(parent, index) in productTree">
                      <v-col :key="parent.id" cols="3" class="my-0 py-0">
                        <v-card outlined class="mr-1 my-0 py-0">
                          <v-card-text class="text-center my-0 py-0">
                            <h4 class="primaryText--text">
                              {{ parent.name }}
                            </h4>
                            <span>{{ parent.type }}</span>
                            <br />
                            <span
                              v-if="parent.hsCode"
                              style="color: var(--v-secondary-base)"
                            >
                              {{ parent.hsCode.alternateCode }}
                            </span>
                            <span v-else>No HS Code</span>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="1"
                        :key="parent.id + 1000"
                        class="text-center"
                        v-if="index < productTree.length - 1"
                      >
                        <v-icon>chevron_right</v-icon>
                      </v-col>
                    </template>
                  </v-row> -->
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card outlined>
                    <v-card-title style="font-size: 16px"
                      >Product Attributes
                      <!-- <v-btn icon color="primary" @click="addMetadata()"
                        ><v-icon>add_circle_outline</v-icon>
                      </v-btn> -->
                      </v-card-title
                    >
                    <v-card-text>
                      <v-list
                        dense
                        subheader
                        style="height: 15vh; overflow-y: auto"
                        v-loading="loadingMetadata"
                        :key="metadataKey"
                      >
                        <v-list-item
                          v-for="(meta, index) in productMetadata"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ meta.value }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ meta.key }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            <v-row justify="center">
                              <v-btn
                                small
                                icon
                                @click="editMetadata(meta, index)"
                                ><v-icon small>edit</v-icon></v-btn
                              >
                              <v-btn
                                small
                                icon
                                color="danger"
                                @click="deleteMetadata(meta)"
                                ><v-icon small>delete</v-icon></v-btn
                              >
                            </v-row>
                          </v-list-item-action> -->
                        </v-list-item>
                        <v-list-item
                          v-if="
                            !loadingMetadata &&
                            productMetadata &&
                            productMetadata.length == 0
                          "
                        >
                          <v-list-item-content class="text-center">
                            <span style="color: grey">No attributes.</span>
                          </v-list-item-content></v-list-item
                        >
                      </v-list>
                    </v-card-text>
                  </v-card>
                  <v-card outlined>
                    <v-card-title style="font-size: 16px"
                      >Child Item(s)
                      <!-- <v-btn
                        icon
                        color="primary"
                        @click="addRelatedProduct(item)"
                        ><v-icon>add_circle_outline</v-icon>
                      </v-btn> -->
                      </v-card-title
                    >
                    <v-card-text>
                      <v-list
                        subheader
                        dense
                        style="height: 15vh; overflow-y: auto"
                        v-loading="loadingProducts"
                      >
                        <v-list-item
                          style="height: 25px"
                          v-for="subItem in subItems.data"
                          :key="subItem.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                              {{ subItem.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ subItem.type }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content v-if="subItem.hsCode">
                            <v-list-item-subtitle class="text-wrap">
                              {{ subItem.hsCode.alternateCode }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-if="
                            !loadingProducts &&
                            subItems &&
                            subItems.data.length == 0
                          "
                        >
                          <v-list-item-content class="text-center">
                            <span style="color: grey">No child items.</span>
                          </v-list-item-content></v-list-item
                        >
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-card outlined>
                    <v-card-title style="font-size: 16px"
                      >Country Codes
                      <v-btn icon color="primary" @click="addCountryCode()"
                        ><v-icon>add_circle_outline</v-icon>
                      </v-btn></v-card-title
                    >
                    <v-card-text>
                      <v-list
                        subheader
                        dense
                        style="height: 39vh"
                        v-loading="loadingCountryProducts"
                      >
                        <v-list-item
                          style="height: 25px"
                          v-for="code in item.productCountries"
                          :key="code.id"
                        >
                          <v-list-item-action>
                            {{ code.code }}
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ code.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ code.description }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row justify="center">
                              <v-btn small icon @click="editCountryCode(code)"
                                ><v-icon small>edit</v-icon>
                              </v-btn>
                              <v-btn
                                small
                                icon
                                @click="deleteCountryCode(code)"
                                color="red"
                                ><v-icon small>delete</v-icon>
                              </v-btn>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                          v-if="
                            !loadingCountryProducts &&
                            item.productCountries &&
                            item.productCountries.length == 0
                          "
                        >
                          <v-list-item-content class="text-center">
                            <span style="color: grey">No specified codes.</span>
                          </v-list-item-content></v-list-item
                        >
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="hsCodeSearchModal"
      persistent
      width="1400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <HSCodeModal @close="hsCodeSearchModal = false" @select="applyHSCode" />
    </v-dialog>

    <v-dialog
      v-model="subItemModal"
      persistent
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card outlined>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Add {{ subItem.type }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="saveSubItem"
            :loading="savingSubProduct"
            :disabled="!subItem.name || !subItem.type"
            >Save</v-btn
          >
          <v-btn text @click="(subItemModal = false), (subItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="subItem.name"
            label="Name*"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="subItem.botanicalName"
            label="Botanical Name"
            outlined
            dense
          ></v-text-field>
          <v-select
            v-model="subItem.type"
            outlined
            dense
            label="Type*"
            :items="filteredTypes"
          ></v-select>
          <v-textarea
            rows="3"
            v-model="subItem.description"
            label="Description"
            outlined
            dense
          ></v-textarea>
          <v-list-item @click="hsCodeSearchModal = true">
            <v-list-item-content>
              <v-list-item-title v-if="subItem.hsCode">
                {{ subItem.hsCode.alternateCode }}
              </v-list-item-title>
              <v-list-item-title v-else> No Code listed </v-list-item-title>
              <v-list-item-subtitle> HS Code </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="filterDialog"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            label="Filter by Product Group"
            outlined
            hide-no-data
            dense
            v-model="selectedProductGroup"
            :loading="searchingGroupProducts"
            :search-input.sync="searchGroupProducts"
            :menu-props="{ closeOnContentClick: true }"
            :items="groupProducts.data"
            item-text="name"
            return-object
            item-value="id"
            clearable
          >
            <template v-slot:selection="{ attr, on }">
              <span v-on="on" v-bind="attr" v-if="selectedProductGroup">{{
                selectedProductGroup.name
              }}</span>
            </template>
            <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
            </v-autocomplete
          >
          <v-autocomplete
            label="Filter by Product"
            outlined
            hide-no-data
            dense
            v-model="selectedParent"
            :loading="searchingProducts"
            :search-input.sync="searchParentProducts"
            :menu-props="{ closeOnContentClick: true }"
            :items="searchProducts.data"
            item-text="name"
            return-object
            item-value="id"
            clearable
          >
            <template v-slot:selection="{ attr, on }">
              <span v-on="on" v-bind="attr" v-if="selectedParent">{{
                selectedParent.name
              }}</span>
            </template>
            <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
            </v-autocomplete
          >
          <v-autocomplete
            label="Filter by Type"
            outlined
            dense
            v-model="params.filter.type"
            clearable
            :items="types"
          ></v-autocomplete>
          <v-checkbox
            dense
            label="Trademark"
            v-model="params.filter.trademark"
          ></v-checkbox>
          <v-checkbox
            dense
            label="Patent"
            v-model="params.filter.patent"
          ></v-checkbox>
          <v-row justify="center" class="mt-3">
            <v-btn
              color="danger"
              small
              text
              style="text-transform: none"
              @click="(params.filter = {}), getProducts()"
              >Clear Filters</v-btn
            >
            <v-btn
              color="success"
              small
              text
              style="text-transform: none"
              @click="getProducts(), (filterDialog = false)"
              >Apply Filter</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="metadataModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Attributes </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(metadataModal = false), (metadataItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Attribute Name"
            v-model="metadataItem.key"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            label="Value"
            v-model="metadataItem.value"
            outlined
            dense
          ></v-text-field>
          <v-row class="mt-3" justify="center">
            <v-btn
              color="primary"
              text
              :disabled="!metadataItem.key || !metadataItem.value"
              @click="saveMetadata"
              :loading="savingMetadata"
              >Save</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="countryCodeModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Country Codes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="(countryCodeModal = false), (countryCodeItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Code"
            v-model="countryCodeItem.code"
            outlined
            dense
          >
          </v-text-field>
          <v-text-field
            label="Name"
            v-model="countryCodeItem.name"
            outlined
            dense
          >
          </v-text-field>
          <v-textarea
            label="Description"
            rows="3"
            v-model="countryCodeItem.description"
            outlined
            dense
          >
          </v-textarea>
          <v-row justify="center" class="mt-2">
            <v-btn
              text
              color="primary"
              :loading="savingCountryProduct"
              :disabled="!countryCodeItem.name || !countryCodeItem.code"
              @click="saveCountryCode"
              >Save</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card></v-dialog
    >
  </div>
</template>
<script>
import HSCodeModal from "../SystemComponents/HSCodeManagement.vue";
export default {
  props: ["country"],
  components: {
    HSCodeModal,
  },
  data: () => ({
    countryCodeItem: {},
    countryCodeModal: false,
    countryProducts: [],
    dialog: false,
    filterDialog: false,
    headers: [
      {
        text: "Action",
        align: "center",
        sortable: false,
        value: "action",
        width: "115px",
      },
      
      {
        text: "Type",
        value: "type",
        align: "left",
      },
      {
        text: "Parent",
        value: "parentProduct.name",
        align: "left",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
      },
      {
        text: "HS Code",
        value: "hsCode.alternateCode",
        align: "left",
      },
     
      {
        text: "Codes",
        value: "codes",
        align: "center",
      },
     
      {
        text: "Description",
        value: "description",
        align: "left",
      },
      {
        text: "Trademark",
        value: "trademark",
        align: "center",
      },
      {
        text: "Patent",
        value: "patent",
        align: "center",
      },
     
    ],
    hsCodeSearchModal: false,
    item: {},
    loading: false,
    loadingCountryProducts: false,
    loadingMetadata: false,
    loadingProducts: false,
    metadataModal: false,
    metadataItem: {},
    metadataKey: 0,
    page: 1,
    params: {
      search: null,
      limit: 18,
      offset: 0,
      filter: {},
    },
    parentProductTimeout: null,
    products: {
      total: 0,
      data: [],
    },
    productItems: {
      total: 0,
      data: [],
    },
    productMetadata: [],
    productTreeLoading: false,
    productTree: [],
    productGroupTimeout: null,
    savingCountryProduct: false,
    savingMetadata: false,
    savingProduct: false,
    savingSubProduct: false,
    searchCountry: null,
    searchingProducts: false,
    searchGroupProducts: null,
    searchingGroupProducts: null,

    searchParentProducts: null,
    selectedParent: null,
    selectedProductGroup: null,
    groupProducts: {
      total: 0,
      data: [],
    },
    searchProducts: {
      total: 0,
      data: [],
    },
    selectedCountry: null,
    searchTimer: null,
    subItemParams: {
      // limit: 10,
      // offset: 0,
      search: null,
    },
    subItems: {
      total: 0,
      data: [],
    },
    subItem: {},
    subItemModal: false,
    types: [
      "Product Group",
      "Product",
      "Variety"
    ],
    updateBadge: 0,
    // data
  }),
  computed: {
    filterCountries() {
      let result = this.countries;
      if (this.searchCountry) {
        result = result.filter((country) =>
          country.name.toLowerCase().includes(this.searchCountry.toLowerCase())
        );
      }
      return result;
    },
    filteredTypes() {
      let result = this.types;
      if (this.item.type) {
        result = result.filter(
          (x, index) => index > this.types.indexOf(this.item.type)
        );
      }
      return result;
    },
  },
  watch: {
    "params.filter": {
      immediate: true,
      handler() {
        this.updateBadge++;
      },
    },
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      await this.getProducts();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.page = 1;
        await this.getProducts();
      },
    },
    "params.search": {
      async handler() {
        await this.getProducts();
      },
    },
    async searchGroupProducts(val) {
      if (this.searchingGroupProducts) {
        clearTimeout(this.productGroupTimeout);
      }
      if (val && val.length > 2) {
        this.searchingGroupProducts = true;

        this.productGroupTimeout = setTimeout(async () => {
          this.groupProducts = await this.$API.getProductsByCountry(this.country.id,{
            params: {
              search: val,
              filter: {
                type: "Product Group",
              },
            },
          });
          this.searchingGroupProducts = false;
        }, 250);
      } else {
        clearTimeout(this.productGroupTimeout);
        this.searchingGroupProducts = false;
      }
    },
    async searchParentProducts(val) {
      let filter = {
        type: "Product"
      }
      if(this.selectedProductGroup){
        filter.productGroupId = this.selectedProductGroup.id
      }
      if (this.searchingProducts) {
        clearTimeout(this.parentProductTimeout);
      }
      if (val && val.length > 2) {
        this.searchingProducts = true;

        this.parentProductTimeout = setTimeout(async () => {
          this.searchProducts = await this.$API.getProductsByCountry(this.country.id, {
            params: {
              search: val,
              filter: filter
            },
          });
          this.searchingProducts = false;
        }, 250);
      } else {
        clearTimeout(this.parentProductTimeout);
        this.searchingProducts = false;
      }
    },
    "selectedParent.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.params.filter.productId = val;
        } else {
          this.params.filter.productId = null;
        }
        this.updateBadge++;
      },
    },
    "selectedProductGroup.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.params.filter.productGroupId = val;
        } else {
          this.params.filter.productGroupId = null;
        }
        this.updateBadge++;
      },
    },
  },
  async created() {},
  methods: {
    addCountryCode() {
      this.countryCodeItem = {
        // hsCodeId: this.item.id,
        name: this.item.name,
        productId: this.item.id,
        countryRegionId: this.country.id,
        countryCode: this.country.iso2,
      };
      this.countryCodeModal = true;
    },
    addChildProduct() {
      this.item = {
        parentProductId: this.selectedParent.id,
        parentProduct: this.selectedParent,
        type: this.getNextType(this.selectedParent.type),
        hsCodeId: this.selectedParent.hsCodeId,
        hsCode: this.selectedParent.hsCode ? this.selectedParent.hsCode : null,
      };
      let obj = {
        productGroupId: this.selectedParent.productGroupId,
        productId: this.selectedParent.productId,
        varietyId: this.selectedParent.varietyId,
        subVarietyId: this.selectedParent.subVarietyId,
        varietyGroupId: this.selectedParent.varietyGroupId,
      };
      this.item = { ...this.item, ...obj };
      this.item[this.getParentField(this.selectedParent.type)] =
        this.selectedParent.id;
      this.dialog = true;
    },
    getParentField(parentField) {
      switch (parentField) {
        case "Product Group":
          return "productGroupId";
        case "Product":
          return "productId";
        case "Variety":
          return "varietyId";
        case "Variety Group":
          return "varietyGroupId";
        case "Sub Variety":
          return "subVarietyId";
      }
    },
    addMetadata() {
      this.metadataItem = {
        productId: this.item.id,
        key: null,
        value: null,
      };
      this.metadataModal = true;
    },
    addProduct() {
      this.productTree = [];
      this.item = {
        type: "Product",
      };
      this.dialog = true;
    },
    addRelatedProduct(item) {
      this.subItem = {
        type: this.getNextType(this.item.type),
        parentProductId: this.item.id,
        hsCode: this.item.hsCode ?? null,
        hsCodeId: this.item.hsCode ? this.item.hsCode.id : null,
        index: this.subItems.data.length,
      };
      let obj = {
        productGroupId: this.item.productGroupId,
        productId: this.item.productId,
        varietyId: this.item.varietyId,
        subVarietyId: this.item.subVarietyId,
        varietyGroupId: this.item.varietyGroupId,
      };
      this.subItem = { ...this.subItem, ...obj };
      this.subItem[this.getParentField(this.item.type)] = this.item.id;
      console.log(this.subItem);
      this.subItemModal = true;
    },
    applyHSCode(item) {
      if (!this.subItemModal) {
        this.item.hsCode = item;
        this.item.hsCodeId = item.id;
        this.hsCodeSearchModal = false;
      } else {
        this.subItem.hsCode = item;
        this.subItem.hsCodeId = item.id;
        this.hsCodeSearchModal = false;
      }
    },
    activeFilters() {
      let keys = Object.keys(this.params.filter);
      let count = 0;
      for (let i = 0; i < keys.length; i++) {
        if (this.params.filter[keys[i]]) {
          count++;
        }
      }
      return count;
    },
    deleteCountryCode(item) {
      this.$confirm(
        "Are you sure you want to delete this product code?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.createProductCountry({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          let find = this.countryProducts.findIndex((x) => x.id == item.id);
          if (find > -1) {
            this.countryProducts.splice(find, 1);
          }
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    deleteItem(item) {
      this.$confirm(
        "Are you sure you want to delete this product?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateProduct({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.getProducts();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    deleteMetadata(item) {
      this.$confirm(
        "Are you sure you want to delete this attribute?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateProductMetadata({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.productMetadata.splice(this.productMetadata.indexOf(item), 1);
          this.metadataKey++;
          // console.log(this.productMetadata);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    editCountryCode(item) {
      this.countryCodeItem = item;
      this.countryCodeModal = true;
    },
    async editItem(item) {
      this.item = item;
      this.productTree = [];
      if (this.item.parentProduct) {
        let find = this.searchProducts.data.find(
          (x) => x.id == this.item.parentProduct.id
        );
        if (!find) {
          this.searchProducts.data.push(this.item.parentProduct);
        }
      }
      this.getSubProducts();
      this.getMetadata();
      this.getProductTree();
      // this.getCountryProducts()
      this.dialog = true;
    },
    editMetadata(item, index) {
      this.metadataItem = item;
      this.metadataItem.index = index;
      this.metadataModal = true;
    },
    editSubItem(item) {
      this.subItem = item;
      this.subItemModal = true;
    },
    async getCountryProducts() {
      this.loadingCountryProducts = true;
      this.countryProducts = await this.$API.getCountryProduct(
        this.item.id,
        this.country.id
      );
      this.loadingCountryProducts = false;
    },
    async getProductTree() {
      this.productTreeLoading = true;
      this.productTree = await this.$API.getProductTree(this.item.id);
      this.productTreeLoading = false;
    },
    async getSubProducts() {
      this.loadingProducts = true;
      this.subItems = await this.$API.getProductSubItems(this.item.id, {
        params: this.subItemParams,
      });
      this.loadingProducts = false;
    },
    async getMetadata() {
      this.loadingMetadata = true;
      this.productMetadata = await this.$API.getProductMetadata(this.item.id);
      this.loadingMetadata = false;
    },
    getNextType(type) {
      let index = this.types.indexOf(type);
      if (index < this.types.length - 1) {
        return this.types[index + 1];
      }
      return null;
    },
    async getProducts() {
      this.updateBadge++;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loading = true;
      this.searchTimer = setTimeout(async () => {
        this.products = await this.$API.getProductsByCountry(this.country.id, {
          params: this.params,
        });
        this.loading = false;
      }, 500);
    },
    async saveProduct() {
      this.savingProduct = true;
      if (this.item.parentProduct) {
        this.item.parentProductId = this.item.parentProduct.id;
      }
      if (this.item.id) {
        await this.$API.updateProduct(this.item);
      } else {
        let result = await this.$API.createProduct(this.item);
        this.item.id = result.id;
      }
      for (let i = 0; i < this.subItems.data.length; i++) {
        if (!this.subItems.data[i].id) {
          this.subItems.data[i].parentProductId = this.item.id;
          this.subItems.data[i][this.getParentField(this.item.type)] =
            this.item.id;
          await this.$API.createProduct(this.subItems.data[i]);
        }
      }
      for (let i = 0; i < this.productMetadata.length; i++) {
        if (!this.productMetadata[i].id) {
          this.productMetadata[i].productId = this.item.id;
          await this.$API.createProductMetadata(this.productMetadata[i]);
        }
      }
      this.getProducts();
      this.subItems = {
        data: [],
        total: 0,
      };
      this.dialog = false;
      this.item = {};
      this.savingProduct = false;
      this.productMetadata = [];
    },
    async saveCountryCode() {
      this.savingCountryProduct = true;
      let result = await this.$API.createProductCountry(this.countryCodeItem);
      let find = this.item.productCountries.findIndex((x) => x.id == result.id);
      if (find > -1) {
        this.item.productCountries[find] = result;
      } else {
        this.item.productCountries.push(result);
      }
      this.countryCodeModal = false;
      this.countryCodeItem = {};
      this.savingCountryProduct = false;
    },
    async saveMetadata() {
      this.savingMetadata = true;
      if (this.metadataItem.id) {
        await this.$API.updateProductMetadata(this.metadataItem);
      } else {
        if (this.item.id) {
          let result = await this.$API.createProductMetadata(this.metadataItem);
          this.metadataItem.id = result.id;
        }
      }

      if (this.metadataItem.index > -1) {
        this.productMetadata[this.metadataItem.index] = this.metadataItem;
      } else {
        this.productMetadata.push(this.metadataItem);
      }
      this.metadataModal = false;
      this.metadataItem = {};
      this.savingMetadata = false;
    },
    async saveSubItem() {
      this.savingSubProduct = true;
      if (this.subItem.id) {
        await this.$API.updateProduct(this.subItem);
      } else {
        if (this.item.id) {
          let result = await this.$API.createProduct(this.subItem);
          this.subItem.id = result.id;
        }
      }
      this.subItems.data[this.subItem.index] = this.subItem;
      this.subItemModal = false;
      this.subItem = {};
      this.savingSubProduct = false;
    },
  },
};
</script>
